import React, { useEffect, useState } from "react";
import HeroSection from "../components/home/hero";
import Widgets from "../components/home/widgets";
import SEO from "../components/common/seo";
import { WidgetsList } from "../helpers/widgets";
import CallToActionIdeaCard from "../components/home/request";
import { featureToggles } from "../helpers/feature-toogles";
import { useSession } from "next-auth/react";
import FilterSection from "../components/home/filter-section";
import { TemplateList } from "../helpers/template-list";
import TemplateWidget from "../components/home/template-widget";
import { Check } from "react-feather";
import { useRouter } from "next/navigation";
import Script from "next/script";

const apps = [
  {
    _id: "0513",
    title: "Pomodoro for Notion",
    slug: "pomodoro",
    type: "paid",
    name: "pomodoro",
    tagline: "Complete pomodoro solution for Notion. Including saving tasks to Notion DB.",
    thumbnail: "/images/apps/pomodoro/home.svg",
    isNew: true,
    category: "productivity",
  },
  {
    _id: "21231288826",
    title: "Notion Importer",
    slug: "notion-importer",
    type: "free",
    name: "notion-importer",
    tagline: "Import data from any source to Notion with a few clicks. It will sync data recurringly.",
    thumbnail: "/images/widget-icons/notionbridge.png",
    isPartner: true,
    category: "productivity",
  },
  {
    _id: "12345",
    title: "Recurring Tasks",
    slug: "recurring-tasks",
    type: "free",
    name: "simplecountdown",
    tagline: "Create recurring tasks in your db with 3 simple steps",
    thumbnail: "/images/apps/recurring-tasks-thumbnail.svg",
    category: "productivity",
  },
  {
    _id: "2024-02-25",
    title: "Dynamic Filters",
    slug: "dynamic-filters",
    type: "free",
    name: "dynamicfilters",
    tagline: "Create dynamic filters for any website based on your notion database or data.",
    thumbnail: "/images/next/dynamic-filter-thumbnail.png",
    isNext: true,
    category: "productivity",
  },
];

const Page = () => {
  const { data: session } = useSession();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const router = useRouter();
  const isBetaUser = featureToggles.betaTesters.includes(session?.user?.email);
  const data = isBetaUser ? WidgetsList : WidgetsList.filter((widget) => widget.betaFeature !== true);

  const handleClick = (title) => {
    setSelectedCategory(title);
  };

  const isFiltered = (type) => {
    return selectedCategory === "All" || selectedCategory === type;
  };

  // useEffect(() => {
  //   if (session?.user && !session?.user?.onboarding) {
  //     router.push("/onboarding");
  //   }
  // }, [session, router, session?.user]);

  function CustomWidget() {
    return (
      <div className="tw-mx-auto tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231] tw-px-6 tw-rounded-lg tw-max-w-4xl">
        <div className="tw-flex tw-py-4 tw-bg-opacity-5" style={{ backgroundImage: "url('/glamorous.svg')" }}>
          <div className="tw-flex-none tw-w-40 tw-mx-auto tw-text-center tw-self-center tw-text-8xl">🎉</div>
          <div className="tw-grow tw-ml-11 tw-items-start">
            <h2 className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-white">We build your custom widget</h2>
            <ul className="text-small text-start tw-text-white tw-px-0 tw-opacity-80 tw-mt-5">
              <li className="tw-mb-1 list-group-item">
                <Check size={18} className="text-white" /> Customized Design your specifications.
              </li>
              <li className="tw-mb-1 list-group-item">
                <Check size={18} className="text-white" /> Exclusive Ownership exclusively yours.
              </li>
              <li className="tw-mb-1 list-group-item">
                <Check size={18} className="text-white" /> Quick Delivery
              </li>
            </ul>
          </div>
          <div className="tw-flex-none tw-w-auto tw-self-center">
            <a href="https://tally.so/r/meBqLk" className="rounded shadow btn btn-primary" target="_blank">
              Get Started
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO canonical={"https://www.notion-widgets.com/"} />
      <div className="px-0 container-fluid">
        {!session && (
          <div className="container px-4 mx-auto">
            <HeroSection />
          </div>
        )}
        <div className="bg-white">
          <div className="container">
            <FilterSection onClick={handleClick} />
            {isFiltered("Counters") && (
              <Widgets data={data} category={"counter"} title={"Counters"} showPromo={false} />
            )}
            {isFiltered("Buttons") && <Widgets data={data} category={"button"} title={"Buttons"} showPromo={false} />}
            {isFiltered("Weather") && <Widgets data={data} category={"weather"} title={"Weather"} showPromo={false} />}
            {isFiltered("Productivity") && (
              <Widgets data={data} category={"productivity"} title={"Productivity"} showPromo={false} />
            )}
            {/* {isFiltered("Templates") && <TemplateWidget />} */}
            {/* <CallToActionIdeaCard /> */}
            <div className="tw-mt-12 tw-mb-28">
              <CustomWidget />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
